import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast, Zoom  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.css';
import MyProvider from './contexts/MyProvider';
import PleaseUpgradePlan from './shared/PleaseUpgradePlan';
import AcceptInvitation from './components/onboarding/AcceptInvitation';
const ProtectedRoutes = lazy(() => import('./protectedRoutes/ProtectedRoutes'));
const ChangeplanToEnterprise = lazy(() => import('./components/settings/ChangeplanToEnterprise'));
const DowngradeUserAddOns = lazy(() => import('./components/settings/DowngradeUserAddOns'));
const UpgradeUserAddOns = lazy(() => import('./components/settings/UpgradeUserAddOns'));
const SubscriptionPlan = lazy(() => import('./components/settings/SubscriptionPlan'));
const Engagement = lazy(() => import('./components/analytics/Engagement'));
const NotFound = lazy(() => import('./publicpages/error'));
const Notification = lazy(() => import('./components/settings/Notification'));
const StorageConfiguration = lazy(() => import('./components/settings/StorageConfiguration'));
const SubscriptionDetail = lazy(() => import('./components/settings/SubscriptionDetail'));
const SubscriptionHistory = lazy(() => import('./components/settings/SubscriptionHistory'));
const SubscriptionAddons = lazy(() => import('./components/settings/SubscriptionAddons'));
const SubscriptionChangePlan = lazy(() => import('./components/settings/SubscriptionChangePlan'));
const PaymentConfirmation = lazy(() => import('./components/onboarding/PyamentConfirmation'));

// Onboarding Module Start
const Register = lazy(() => import('./components/onboarding/Register'));
const VerifyOtp = lazy(() => import('./components/onboarding/VerifyOtp'));
const ProfileAdmin = lazy(() => import('./components/onboarding/ProfileAdmin'));
const ProfileMember = lazy(() => import('./components/onboarding/ProfileMember'));
const Projects = lazy(() => import('./components/onboarding/Projects'));
const Officeteams = lazy(() => import('./components/onboarding/Officeteams'));
const Login = lazy(() => import('./components/onboarding/Login'));
const ResetPassword = lazy(() => import('./components/onboarding/ResetPassword'));
const ResetPasswordEmail = lazy(() => import('./components/onboarding/ResetPasswordEmail'));
const ResetPasswordOTP = lazy(() => import('./components/onboarding/ResetPasswordOTP'));
const Confirmation = lazy(() => import('./components/onboarding/PasswordConfirmation'));
const Portals = lazy(() => import('./components/onboarding/Portals'));

// Task Module Start
const Tasksmod = lazy(() => import('./components/task/Tasksmod'))
const Officeteammod = lazy(() => import('./components/team/Officeteammod'))
//const TeamTask = lazy(() => import('./components/team/TeamTask'));

// Meeting Module Start
const Meetings = lazy(() => import('./components/meeting/Officemeetingmod'));
const MeetingDetails = lazy(() => import('./components/meeting/MeetingDetailForTask'));

const ProjectMod = lazy(() => import('./components/project/ProjectMod'));
const Milestone = lazy(() => import('./components/project/Milestone'));
const ProjectTasks = lazy(() => import('./components/project/ProjectTasks'));
const ProjectTeamMembers = lazy(() => import('./components/project/ProjectTeamMembers'));
const ProjectIssues = lazy(() => import('./components/project/ProjectIssues'));
const ProjectDocuments = lazy(() => import('./components/project/ProjectDocuments'));
const ProfileSetting = lazy(() => import('./components/settings/ProfileSetting'));
const ProfileUser = lazy(() => import('./components/settings/User'));
// Analytics Module Start
const Analyticsmod = lazy(() => import('./components/analytics/Analytics'));


//const SignalRComponent = lazy(() => import('./services/SignalRComponent'));
const { AuthorizedInner } = require('./middlewares/AuthorizedInner');
const { AuthorizedWalkthrough } = require('./middlewares/AuthorizedWalkthrough');
const EnagagementSurvey = lazy(() => import('./components/surveys/EnagagementSurvey'));
const HappinessSurvey = lazy(() => import('./components/surveys/HappinessSurvey'));
const Integrations = lazy(() => import('./components/settings/Integrations'));
const IntegrationsDemo = lazy(() => import('./components/settings/IntegrationsDemo'));
const Reminders = lazy(() => import('./components/settings/Reminders'));

// const MsTeamMeeting = lazy(() => import('./components/meeting/MsTeam/MsTeamMeeting'));

////// Super Admin Module ////////////
const SuperAdminDashboard = lazy(() => import('./superAdmin/pages/SuperAdminDashboard'));
const ClientUsageReport = lazy(() => import('./superAdmin/pages/ClientUsageReport'));
const RevenueReport = lazy(() => import('./superAdmin/pages/RevenueReport'));
const SuperAdminLogin = lazy(() => import('./superAdmin/pages/SuperAdminLogin'));

const ZoomAuth = lazy(() => import('./components/meeting/Zoom/ZoomAuth'));
const GoogleAuth = lazy(() => import('./components/meeting/GoogleMeet/GoogleAuth'));
const IcalAuth = lazy(() => import('./components/meeting/iCal/IcalAuth'));
const MsTeamMeeting = lazy(() => import('./components/meeting/MsTeam/MsTeamMeeting'));
const MSTeamsAuth = lazy(() => import('./components/meeting/MsTeam/MSTeamsAuth'));
const Forbidden=lazy(()=>import('./shared/forbidden'))




function App() {
  return (
    <MyProvider>
      <BrowserRouter>
        <ToastContainer 
        position="top-center" 
        autoClose={5000} 
        hideProgressBar={true} 
        newestOnTop={false} 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom} // You can also use Slide, Bounce, etc.
        style={{ top: "50%", transform: "translateY(-50%)" }}/>

        <Suspense fallback={<div></div>}>
          <Routes>
            {/* <Route path="/signalr" element={<SignalRComponent clientId="6" userId="534" />} /> */}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/accept-invitation" element={<AcceptInvitation />} />
            <Route path="/profileadmin" element={<ProfileAdmin />} />
            <Route path="/profilemember" element={<ProfileMember />} />
            <Route path="/officeteams" element={<Officeteams />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/resetpasswordemail" element={<ResetPasswordEmail />} />
            <Route path="/resetpasswordotp" element={<ResetPasswordOTP />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/please-upgrade-plan" element={<PleaseUpgradePlan />} />
            <Route path="/payment-confirmation/:hostedpage_id" element={<PaymentConfirmation />} />
            <Route path="*" element={<NotFound />} />
            {/* <Route element={<ProtectedRoutes />}> */}
            <Route path="/engagement" element={<AuthorizedInner cmp={Engagement} routeFor={"Admin"}/>} />
            <Route path="/portals" element={<Portals />} />
            <Route path="/surveys/engagement" element={<AuthorizedInner cmp={EnagagementSurvey} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/surveys/happiness" element={<AuthorizedInner cmp={HappinessSurvey} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/analytics" element={<AuthorizedInner cmp={Analyticsmod} routeFor={"Admin"}/>} />
            <Route path="/tasks/:taskId?" element={<AuthorizedInner cmp={Tasksmod} />} />
            {/* <Route path="/tasks/:taskId" element={<AuthorizedInner cmp={TaskDetail}/>} /> */}
           

            <Route path="/teams/:teamid?/:memberemail?" element={<AuthorizedInner cmp={Officeteammod} />} />
            <Route path='/meetings' element={<AuthorizedInner cmp={Meetings} />} />
            <Route path='/meeting-details/:meetingid' element={<AuthorizedInner cmp={MeetingDetails} />} />
            <Route path="/project/:projectid" element={<ProjectMod />} />
            <Route path="/project-milestones/:projectid" element={<Milestone />} />
            <Route path="/project-tasks/:projectid" element={<ProjectTasks />} />
            <Route path="/project-issues/:projectid" element={<ProjectIssues />} />
            <Route path="/project-members/:projectid" element={<ProjectTeamMembers />} />
            <Route path="/project-documents/:projectid" element={<ProjectDocuments />} />
            <Route path="/settings/profile-setting" element={<AuthorizedInner cmp={ProfileSetting} headerType={"SettingsLayout"} />} />
            <Route path="/settings/notification" element={<AuthorizedInner cmp={Notification} headerType={"SettingsLayout"} />} />
            <Route path="/settings/storage" element={<AuthorizedInner cmp={StorageConfiguration} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription" element={<AuthorizedInner cmp={SubscriptionDetail} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/User" element={<AuthorizedInner cmp={ProfileUser} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/paymenthistory" element={<AuthorizedInner cmp={SubscriptionHistory} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/upgrade" element={<AuthorizedInner cmp={SubscriptionAddons} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/upgradeplan" element={<AuthorizedInner cmp={SubscriptionPlan} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/changeplan" element={<AuthorizedInner cmp={SubscriptionChangePlan} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/upgrade-user-addons" element={<AuthorizedInner cmp={UpgradeUserAddOns} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/downgrade-user-addons" element={<AuthorizedInner cmp={DowngradeUserAddOns} headerType={"AdministrativeSettingsLayout"} />} />
            <Route path="/settings/subscription/changeplan-Enterprise" element={<AuthorizedInner cmp={ChangeplanToEnterprise} headerType={"AdministrativeSettingsLayout"} />} />
             

            <Route path="/settings/reminders" element={<AuthorizedInner cmp={Reminders} headerType={"SettingsLayout"} />} />
            <Route path="/settings/integrations" element={<AuthorizedInner cmp={Integrations} headerType={"SettingsLayout"} />} />
            <Route path="/settings/integrations-demo" element={<AuthorizedInner cmp={IntegrationsDemo} headerType={"SettingsLayout"} />} />

           
            {/* ///// Super Admin Module ///// */}
            <Route path="/superadmin/dashboard" element={<AuthorizedInner cmp={SuperAdminDashboard} headerType={"SuperAdminLayout"} />} />
            <Route path="/superadmin/usagereports" element={<AuthorizedInner cmp={ClientUsageReport} headerType={"SuperAdminLayout"} />} />
            <Route path="/superadmin/revenuereport" element={<AuthorizedInner cmp={RevenueReport} headerType={"SuperAdminLayout"} />} />
            <Route path="/superadmin/login" element={<SuperAdminLogin />} />
            <Route path="/zoomAuth" element={<ZoomAuth />} />
            <Route path="/googleAuth" element={<GoogleAuth/>} />
            <Route path="/auth-ical" element={<IcalAuth />} />
            <Route path="/auth/callback" element={<MsTeamMeeting />} />
            <Route path="/unauthorized" element={<Forbidden />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;
