import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const SuperAdminSidebar = () => {
  const location = useLocation();

  useEffect(() => {   
  }, [location]);
  
  const isActivePath = (path) => location.pathname === path;
  const isActivePathInRange = (basePath) => location.pathname.startsWith(basePath);

  return (
    <div className="setting-left">
      <div className="setting-nav">
        <ul className="sideways">
          <li className={isActivePath("/superadmin/dashboard") ? "active" : ""}>
            <Link to="/superadmin/dashboard">
              <i className="fa fa-tachometer"></i> Dashboard
            </Link>
          </li>
          {/* <li className={isActivePathInRange("/superadmin/usagereports") || isActivePathInRange("/superadmin/revenuereport") ? "active submenu" : "submenu"}>
            <Link to="#">
              <i className="fa fa-file"></i> Usage Reports
            </Link>
            <ul className={isActivePathInRange("/superadmin/usagereports") || isActivePathInRange("/superadmin/revenuereport") ? "submenu-list-active" : "submenu-list"}>
              <li className={isActivePath("/superadmin/usagereports") ? "active" : ""}>
                <Link to="/superadmin/usagereports">
                  <i className="fa fa-file me-1"></i> Client Usage Report
                </Link>
              </li>
              <li className={isActivePath("/superadmin/revenuereport") ? "active" : ""}>
                <Link to="/superadmin/revenuereport">
                  <i className="fa fa-file me-1"></i> Revenue Report
                </Link>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SuperAdminSidebar;
